import React from "react";
import { internalNav } from "@utils/variables";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { motion } from "framer-motion";
import tw from "twin.macro";
import { lang } from "@utils/helpers";

import CustomLink from "@components/link";

const mainNav = internalNav.slice().sort((a, b) => a.mainMenuOrder - b.mainMenuOrder);

const isSendRequestLink = (pageName: string) => pageName === 'Send request';

const NavLink = styled(CustomLink)<{ whiteColor: boolean, isSendRequestLink: boolean }>`
  ${tw`inline-block transition-colors duration-300 lg:relative lg:-top-50`}

  ${({ isSendRequestLink }) => isSendRequestLink ?
    tw`
      font-pangramBold text-orange mt-110 mb-30 whitespace-no-wrap -tracking-013
      lg:my-0 lg:text-base lg:leading-40` :
    tw`
      max-w-200 text-primary text-xl leading-33 mb-35 whitespace-no-wrap -tracking-016 lg:-tracking-013
      lg:relative lg:mr-80 lg:text-second lg:my-0 lg:text-base lg:leading-40`
  }
  
  &:hover {
    ${({ isSendRequestLink }) => isSendRequestLink ? tw`text-orangeHover` : tw`text-portfolioText`}
  }
  
  ${({ whiteColor, isSendRequestLink }) => whiteColor && !isSendRequestLink && tw`lg:text-white`}

  &.active {
    ${tw`text-portfolioText`}
  }
`;

const NavLinkIndex = styled.span<{ isHidden: boolean }>`
  ${tw`font-pangramBold hidden absolute -top-15 -right-10 text-xs`}
  
  ${({ isHidden }) => isHidden ? '' : tw`lg:block`}
`;

const NavLinks = ({ width, whiteLinks }: { width: number, whiteLinks: boolean }) => {
  const { t } = useTranslation();
  const sendRequestDelay = lang === 'pl' ? .797 : .667;

  return (
    <>
      {mainNav.map(({ name, href, mainMenuOrder, language }) => {
        if (!language || language === lang) {
          return ( 
            <motion.div
              key={name}
              initial={ 'initial' }
              animate={ width >= 1024 && 'visible' }
              transition={{
                y: { 
                  duration: .5, 
                  delay: isSendRequestLink(name) ? .667 : .250 + mainMenuOrder * .125, 
                  ease: 'easeOut' 
                },
                opacity: { 
                  duration: .3, 
                  delay: .65, 
                  ease: 'linear' 
                }
              }}
              variants={{
                visible: { y: 50, opacity: 1 },
                initial: { y: 0, opacity: 0 }
              }}
            >
              <NavLink
                to={href}
                activeClassName="active"
                isSendRequestLink={isSendRequestLink(name)}
                whiteColor={whiteLinks}
              >
                {t(`Navigation:${name}`)}
                <NavLinkIndex isHidden={isSendRequestLink(name)}>
                  {mainMenuOrder}
                </NavLinkIndex>
              </NavLink>
            </motion.div>
          )
        }
      })}
    </>
  )
};

export default NavLinks;
