import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import { Link } from 'gatsby';

const getLocalizedPath = require('@i18n/utils/getLocalizedPath');

type LinkProps = {
  children: React.ReactNode,
  to: string,
  type: 'noIntl' | null
};

const CustomLink = ({ children, to, type, ...props }: LinkProps) => {
  if (type === 'noIntl') {
    return (
      <Link to={to} {...props}>
        {children}
      </Link>
    )
  } else {
    const intl = useIntl();

    return (
      <Link to={getLocalizedPath(to, intl.locale)} {...props}>
        {children}
      </Link>
    );
  }
};

export default CustomLink;
