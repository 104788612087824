import { navTypes, OfferStepsTypes } from '@utils/types';

export const sanityConfig = {
  projectId: '4rl2er8m',
  dataset: 'production'
};

export const pagesRoutes = {
  home: '/',
  about: '/o-nas',
  offer: '/oferta',
  contact: '/kontakt',
  thankYou: '/dziekujemy',
  portfolio: '/portfolio',
  blog: '/blog',
} as const;

export const internalNav: navTypes[] = [
  {
    name: 'Portfolio',
    href: pagesRoutes.portfolio,
    mainMenuOrder: 2
  },
  {
    name: 'Offer',
    href: pagesRoutes.offer,
    mainMenuOrder: 1
  },
  {
    name: 'About',
    href: pagesRoutes.about,
    mainMenuOrder: 3
  },
  {
    name: 'Send request',
    href: pagesRoutes.contact,
    mainMenuOrder: 5
  },
  {
    name: 'Blog',
    href: pagesRoutes.blog,
    mainMenuOrder: 4,
    language: 'pl'
  }
];

export const socialMedia: string[] = [
  'Facebook',
  'Instagram',
  'Slideshare'
];

export const breakpoints: { [key: string]: string } = {
  xs: '0px',
  sm: '640px',
  md: '768px',
  lg: '1024px',
  xl: '1280px',
  xxl: '1921px',
} as const;

export const people = [
  'Beata',
  'Marta'
] as const;

export const offerSteps: Record<OfferStepsTypes, string>[] = [
  {
    name: 'Communication',
    project: 'Blue Media',
    slug: 'blue-media',
    customClasses: 'mb-10 lg:-mb-120'
  },
  {
    name: 'Webwriting',
    project: 'eurobank',
    slug: 'eurobank',
    customClasses: 'mb-10 lg:mb-20'
  },
  {
    name: 'Naming',
    project: 'Droids on Roids',
    slug: 'droids-on-roids',
    customClasses: 'mb-20 lg:mb-50'
  }
];

export const regExps: Record<string, RegExp> = {
  email: /^(\D)+(\w)*((\.(\w)+)?)+@(\D)+(\w)*((\.(\D)+(\w)*)+)?(\.)[a-z]{2,}$/,
  tel: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/
};

export const headerAnimationDuration = 6.5;

export const arrowAnimationDuration = 5;
export const arrowPrimaryDelay = 1.5;
export const arrowSecondaryDelay = 3;
