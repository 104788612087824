import React from "react";
import { useTranslation } from "react-i18next";
import tw from "twin.macro";

const address: string[] = ['name', 'street', 'city'];

const BoxAddress = tw.div`
  absolute left-10 right-0 bottom-80 mr-10 pt-22 pl-30 pr-25 pb-40 text-primary text-sm leading-30
  bg-footerLight -tracking-021
  lg:top-50 lg:bottom-0 lg:left-auto lg:-right-200 lg:w-540 lg:pt-50 lg:px-58 lg:pb-100
  xl:-right-170 xl:px-78
`;

const Header = tw.div`
  font-pangramBold mb-10 text-xs text-second leading-32 -tracking-01
  lg:mb-5 lg:text-sm
`;

const AddressWrapper = tw.div`
  mb-20 lg:mb-60
  lg:text-md lg:leading-35
`;

const MailWrapper = tw.div`font-pangramBold lg:text-lg`;

const Mail = tw.span`mr-30`;

const FooterBoxAddress = () => {
  const { t } = useTranslation();

  return (
    <BoxAddress>
      <Header>
        {t('Footer:Office')}
      </Header>
      <AddressWrapper>
        {address.map((addressType: string, index: number) => (
          <p key={index}>
            {t(`Contact:Company ${addressType}`)}
          </p>
        ))}
      </AddressWrapper>
      <MailWrapper>
        <Mail>
          {t('Contact:Email')}
        </Mail>
        <a href={`mailto:${t('Contact:Office email')}`}>
          {t('Contact:Office email')}
        </a>
      </MailWrapper>
    </BoxAddress>
  );
};

export default FooterBoxAddress;
