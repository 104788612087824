import React from "react";
import useWindowSize from "@utils/useWindowSize";
import { Helmet } from "react-helmet";
import tw from "twin.macro";

import NavLinks from "@components/header/nav-links";
import NavContact from "@components/header/nav-contact";
import SwitchLang from "@components/switch-lang"

type NavProps = {
  isMenuActive: boolean,
  whiteLinks: boolean,
  isPortfolioCasePage: boolean
};

const NavWrapper = tw.div`
  absolute top-0 bottom-0 inset-x-0 z-2 flex flex-col h-full pt-95 pl-40 bg-white overflow-y-auto
  lg:static lg:flex-row lg:p-0 lg:overflow-visible lg:bg-transparent
`;

const Nav = ({ isMenuActive, whiteLinks }: NavProps) => {
  const { width } = useWindowSize();

  return (
    <>
      <Helmet bodyAttributes={{ class: `${isMenuActive ? 'overflow-hidden': ''}` }} />
      <NavWrapper className={`nav ${isMenuActive ? 'open' : ''}`}>
        <NavLinks width={width} whiteLinks={whiteLinks} />
        <SwitchLang 
          width={width} 
          whiteLinks={whiteLinks && !isMenuActive} 
          isPortfolioCasePage={whiteLinks}
        />
        <NavContact />
      </NavWrapper>
    </>
  );
};

export default Nav;
