import i18n from "i18next";
import { isEngVersion } from "@utils/helpers";
import { initReactI18next } from "react-i18next";

import PL from "@translations/pl/translation.json";
import EN from "@translations/en/translation.json";

const resources = {
  pl: {
    translation: PL,
  },
  en: {
    translation: EN,
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: !!isEngVersion ? 'en' : 'pl',
    keySeparator: ':',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
