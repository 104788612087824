module.exports = [
  {
    default: true,
    locale: 'pl',
    label: 'Polish',
    routes: {
      '/': '/',
      '/404': '/404',
      '/404.html': '/404.html',
      '/dziekujemy': '/dziekujemy',
      '/kontakt': '/kontakt',
      '/o-nas': '/o-nas',
      '/oferta': '/oferta',
      '/regulamin': '/regulamin',
      '/portfolio': '/portfolio',
      '/blog': '/blog',
    },
  },
  {
    locale: 'en',
    label: 'English',
    routes: {
      '/': '/',
      '/404': '/404',
      '/404.html': '/404.html',
      '/dziekujemy': '/thank-you',
      '/kontakt': '/contact',
      '/o-nas': '/about-us',
      '/oferta': '/offer',
      '/regulamin': '/',
      '/portfolio': '/portfolio',
      '/blog': '/',
    },
  },
];
