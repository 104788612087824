import React from "react";
import { socialMedia, internalNav } from "@utils/variables";
import { useTranslation } from "react-i18next";
import { isEngVersion } from "@utils/helpers";
import tw from "twin.macro";

import CustomLink from "@components/link";

const Wrapper = tw.div`
  py-30 px-20 text-white bg-footerDark
  lg:pt-100 lg:px-110 lg:pb-88
  xl:-ml-110
`;

const BoxNavigationWrapper = tw.div`
  max-w-xs text-footerLight text-sm leading-30 -tracking-021
  md:max-w-none md:flex
  lg:text-md
`;

const NavigationWrapper = tw.div`mb-20 md:mr-65 lg:mb-0 lg:mr-0 xl:mr-65`;

const Navigation = tw.div`flex flex-wrap lg:leading-42`;

const NavigationTitle = tw.div`
  mb-10 font-pangramBold text-white text-xs leading-32 -tracking-01
  lg:mb-5 lg:text-sm
`;

const StyledLink = tw(CustomLink)`w-1/2`;

const SocialMedia = tw.div`flex justify-between w-160 lg:leading-42`;

const SocialMediaTitle = tw.div`
  mb-10 font-pangramBold text-white text-xs leading-32 -tracking-01
  lg:mb-5 lg:text-sm
`;

const Copyrights = tw.div`mt-290 text-xs leading-30 -tracking-021 sm:text-sm lg:mt-88`;

const FooterBoxNavigation = () => {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();

  return (
    <Wrapper>
      <BoxNavigationWrapper>
        <NavigationWrapper>
          <NavigationTitle>
            {t('Footer:Navigation')}
          </NavigationTitle>
          <Navigation>
            {internalNav.map(({ name, href }) => (
              <StyledLink to={href} key={name}>
                {t(`Navigation:${name}`)}
              </StyledLink>
            ))}
            { !isEngVersion && (
              <StyledLink to="/regulamin">
                {t(`Navigation:Terms`)}
              </StyledLink>
            )}
          </Navigation>
        </NavigationWrapper>
        <div>
          <SocialMediaTitle>
            {t('Social media:Social media')}
          </SocialMediaTitle>
          <SocialMedia>
            {socialMedia.map((name: string) => (
              <a
                href={t(`Social media:${name} address`)}
                key={name}
                target="_blank"
                rel="noopener"
              >
                {t(`Social media:${name}`)}
              </a>
            ))}
          </SocialMedia>
        </div>
      </BoxNavigationWrapper>
      <Copyrights>
        {currentYear}
        {t('Footer:Copyrights')}
        <a href='https://naturaily.com' target="_blank" rel="noopener">Naturaily</a>
      </Copyrights>
    </Wrapper>
  );
};

export default FooterBoxNavigation;
