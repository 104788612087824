import React from "react";
import { CSSTransition } from 'react-transition-group';
import { useTranslation } from "react-i18next";
import { isEngVersion } from "@utils/helpers";
import Img from "@images/dajemyslowo.svg";
import { up } from "styled-breakpoints";
import styled from "styled-components";
import { motion } from "framer-motion";
import tw from "twin.macro";

import CustomLink from "@components/link";
import Info from "@images/info.svg";

const LogoWrapper = tw(motion.div)`
  relative w-full max-w-100 z-3
  lg:max-w-170
  xl:opacity-0
`;

const StyledLogo = styled(Img)<{ white: boolean }>`
  .logo-color {
    ${tw`transition-all duration-300`}
    ${({ white }) => white ? tw`fill-white` : ''}
  }
`;

const Icon = styled(Info)`
  ${tw`absolute -bottom-5 right-0 cursor-pointer z-1 lg:bottom-16`}
  
  .background, .i {
    ${tw`transition duration-300`}
  }

  &:hover, &.active {
    .background {
      fill: #1C2E4D;
    }
    
    .i {
      fill: #F8CD5C;
    }
  }
`;

const Tooltip = styled.div`
  background-image: linear-gradient(170.5deg,rgba(0,0,0,0) 68px,#1C2E4D 69px,#1C2E4D 462px,rgba(0,0,0,0) 463px);
  ${tw`absolute top-40 right-0 pt-95 px-25 pb-60 lg:top-8 lg:left-80 lg:right-auto lg:px-45`}
  
  ${up('lg')} {
    background-image: linear-gradient(170.5deg,rgba(0,0,0,0) 68px,#1C2E4D 69px,#1C2E4D 422px,rgba(0,0,0,0) 423px);
  }
`;

const Description = styled.div`
  ${tw`w-230 mb-30 text-white leading-30 -tracking-024 lg:w-320`}

  span {
    color: #F8CD5C;
    ${tw`underline`}
  }
`;

const TooltipLogo = styled(Img)`
  ${tw`w-85`}

  .logo-color {
    fill: #F8CD5C;
  }
`;

const Logo = ({ whiteLogo }: { whiteLogo: boolean }) => {
  const { t } = useTranslation();
  const [isOpen, setOpen] = React.useState(false);

  return (
    <LogoWrapper
      initial={ 'hidden' }
      animate={ 'visible'}
      transition={{ duration: .625, delay: .375, ease: [.84, 0, .16, 1] }}
      variants={{
        'visible': { scale: 1, rotate: 0 },
        'hidden': { scale: 0, rotate: -8, opacity: 1 },
        'initial': { scale: 1, opacity: 1 }
      }}
    >
      <CustomLink to="/">
        <StyledLogo white={whiteLogo} />
      </CustomLink>
      { isEngVersion && (
        <Icon
          onClick={() => setOpen(!isOpen)}
          className={isOpen && 'active'}
        />
      )}
      <CSSTransition
        in={isOpen}
        timeout={300}
        classNames="tooltip"
        unmountOnExit
      >
        <Tooltip>
          <Description dangerouslySetInnerHTML={{__html: t('tooltip')}} />
          <TooltipLogo />
        </Tooltip>
      </CSSTransition>
    </LogoWrapper>
  );
};

export default Logo;
