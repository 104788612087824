import React, { useState, useEffect } from "react";
import { isEngVersion } from "@utils/helpers";
import { createUrl } from "@utils/caseHelpers";
import { useIntl } from 'gatsby-plugin-intl';
import styled from "styled-components";
import { motion } from "framer-motion";
import tw from "twin.macro";

const getLocalizedPath = require('@i18n/utils/getLocalizedPath');
const languagesConfig = require('@i18n/config/languages');

type SwitchLangProps = {
  width: number, 
  whiteLinks: boolean, 
  isPortfolioCasePage: boolean
};

const Wrapper = tw(motion.div)`
  absolute top-480 left-250 z-1
  lg:relative lg:-top-118 lg:left-auto lg:ml-55
`;

const Btn = styled.a<{ whiteLinks: boolean }>`
  ${({ whiteLinks }) => whiteLinks ? tw`text-white border-white` : tw`text-grey border-grey opacity-50`}
  ${tw`
    flex justify-center items-center w-56 h-56 -mt-10 -tracking-013 border rounded-full
    cursor-pointer transition duration-300
    hover:bg-orange hover:text-white hover:border-orange hover:opacity-100
  `}
`;

const SwitchLang = ({ width, whiteLinks, isPortfolioCasePage }: SwitchLangProps) => {
  const [switchShown, setSwitchShown] = useState(false);
  const intl = useIntl();
  const lang = isEngVersion ? 'pl' : 'en';
  const delay = isEngVersion ? .742 : .852;
  const pathName = typeof window !== 'undefined' ? window.location.pathname : '/';

  useEffect(() => { setSwitchShown(true) }, []);

  const handlePath = () => {
    const fullPath = isEngVersion ? pathName.substring(3, pathName.length) : pathName;
    const path = fullPath.slice(-1) === '/' ? fullPath.slice(0, -1) : fullPath;

    if (isPortfolioCasePage) {
      const slug = path.split('/').pop();
      return createUrl(slug, lang);
    } else {
      const { routes } = languagesConfig.find((lang: any) => lang.locale === intl.locale);
      const originPath = Object.keys(routes).find(key => routes[key] === path) || '/';
      return getLocalizedPath(originPath, lang);
    }
  };

  return (
    <Wrapper
      initial={'initial'}
      animate={ width >= 1024 && 'visible' }
      variants={{
        visible: {
          y: 120,
          transition: {
            delay: delay,
            duration: .75,
            ease: [.84, 0, .16, 1]
          }
        },
        initial: {
          y: 0
        }
      }}
    >
      { switchShown ?  (
        <Btn whiteLinks={whiteLinks} href={handlePath()}>
          { lang }
        </Btn>
      ) : ( 
        null
      )}
      
    </Wrapper>
  );
};

export default SwitchLang;
