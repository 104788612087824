export const createOfferStepsId = (idName: string) => `#${idName}`;

export const lottieOptions = (elemJson: any) => {
  return {
    loop: true,
    autoplay: true,
    animationData: elemJson,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
};

export const path = typeof window !== 'undefined' ? window.location.pathname : '';
export const isEngVersion = path.split('/').find(x => x === 'en');
export const lang = isEngVersion ? 'en' : 'pl';
export const rawName = '_raw' + lang[0].toUpperCase() + lang.substring(1);

export const portableToPlain = (blocks = []) => {
  return blocks
    .map(block => {
      if (block._type !== 'block' || !block.children) return '';
      return block.children.map(child => child.text).join('')
    })
    .join('\n\n');
};
