/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

type MetaProps = JSX.IntrinsicElements['meta']

type SEOProps = {
  description?: string,
  lang?: string,
  meta?: MetaProps[]
  title: string
}

const SEO = ({ description, lang = 'pl', meta = [], title }: SEOProps) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `,
  );

  const metaDescription = description || site.siteMetadata.description;

  const constantMeta: MetaProps[] = [
    {
      name: 'description',
      content: metaDescription,
    },
    {
      property: 'og:title',
      content: `${title} | ${site.siteMetadata.title}`,
    },
    {
      property: 'og:description',
      content: metaDescription,
    },
    {
      property: 'og:type',
      content: 'website',
    },
    {
      property: 'og:locale',
      content: lang === 'pl' ? 'pl_PL' : 'en_EN',
    },
    {
      name: 'twitter:card',
      content: 'summary',
    },
    {
      name: 'twitter:site',
      content: 'dajemyslowo',
    },
    {
      name: 'twitter:title',
      content: `${title} | ${site.siteMetadata.title}`,
    },
    {
      name: 'twitter:description',
      content: metaDescription,
    },
  ]

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={constantMeta.concat(meta)}
    />
  )
}

export default SEO;
