import React from "react";
import tw from "twin.macro";

import FooterBoxNavigation from "@components/footer/box-navigation";
import FooterBoxAddress from "@components/footer/box-address";
import FooterGradient from "@components/footer/gradient";

const FooterWrapper = tw.footer`
  container relative mx-auto mt-40 mb-10 pl-20 pr-10
  md:mb-0
  lg:mt-25 lg:p-0
  xxl:max-w-1280!
`;

const Footer = () => (
  <FooterWrapper>
    <FooterBoxNavigation />
    <FooterBoxAddress />
    <FooterGradient />
  </FooterWrapper>
);

export default Footer;
