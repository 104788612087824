import React, { useState } from "react";
import tw from "twin.macro";

import Hamburger from "@components/header/hamburger";
import Logo from "@components/header/logo";
import Nav from "@components/header/nav";

const HeaderWrapper = tw.header`
  container mx-auto
  lg:flex lg:mt-40 lg:mb-35
  xxl:max-w-1280!
`;

const MobileSection = tw.div`
  flex justify-between items-center w-full pt-20 px-20 mb-30
  lg:m-0 lg:p-0
`;

const Header = ({ isPortfolioCasePage = false }: { isPortfolioCasePage?: boolean }) => {
  const [isMobileMenuOpen, setMobileMenu] = useState(false);

  const handleMobileMenu = (prevIsMobileOpen: boolean) => {
    setMobileMenu(!prevIsMobileOpen);
  };

  return (
    <HeaderWrapper>
      <MobileSection>
        <Hamburger isMenuActive={isMobileMenuOpen} changeMenuState={handleMobileMenu} />
        <Logo whiteLogo={isPortfolioCasePage && !isMobileMenuOpen} />
      </MobileSection>
      <Nav whiteLinks={isPortfolioCasePage} isMenuActive={isMobileMenuOpen} />
    </HeaderWrapper>
  );
};

export default Header;
