import React from "react";
import styled from "styled-components";
import tw from "twin.macro";

type HamburgerProps = {
  isMenuActive: boolean,
  changeMenuState: Function
};

const Wrapper = tw.div`relative w-30 h-30 bg-orange rounded-full cursor-pointer z-10 lg:hidden`;

const StyledHamburgerBar = tw.span`
  absolute left-1/2 block w-1/2 h-2 bg-white opacity-100 rounded-full
  transform -translate-x-1/2
  transition-all ease-in-out duration-300
`;

const OutsideBar = styled(StyledHamburgerBar)<{ top: boolean }>`
  ${({ top }) => top ? tw`top-9` : tw`top-19`}
  
  ${Wrapper}.open & {
    ${tw`top-14 left-1/2 w-0`}
  }
`;

const MiddleBar = styled(StyledHamburgerBar)`
  ${tw`top-14`}
  
  ${Wrapper}.open & {
    ${tw`left-1/4 transform -rotate-45`}
    
    :nth-child(2) {
      ${tw`rotate-45`}
    }
  }
`;

const Hamburger = ({ isMenuActive, changeMenuState }: HamburgerProps) => (
  <Wrapper
    className={isMenuActive ? 'open' : ''}
    onClick={() => changeMenuState(isMenuActive)}
  >
    <OutsideBar top />
    <MiddleBar />
    <MiddleBar />
    <OutsideBar />
  </Wrapper>
);

export default Hamburger;
