import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import tw from 'twin.macro';

const fullWidth4k = styled.div`
  && {
    ${up('xxl')} {
      ${tw`-left-640 min-w-2560 w-2560`}
    }
  }
`;

export const fullWidthBox = tw(fullWidth4k)`relative -left-320 min-w-1920 w-1920`;
