import React from "react";
import { fullWidthBox } from "@utils/styled-components";
import styled from "styled-components";
import tw from "twin.macro";

const GradientWrapper = tw(fullWidthBox)`hidden h-0 md:block`;

const Gradient = styled.div`
  background: linear-gradient(180deg, #FFFFFF 0%, #F5FDFF 100%);
  ${tw`absolute bottom-0 w-full h-990 -z-1`}
`;

const FooterGradient = () => (
  <GradientWrapper>
    <Gradient />
  </GradientWrapper>
);

export default FooterGradient;
