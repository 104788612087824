import { graphql, useStaticQuery } from "gatsby";

export const createUrl = (slug, lang) => {
  const url = lang === 'en'
    ? `/en/portfolio/${slug}` 
    : `/portfolio/${slug}`;
  
  return url;
};

export const returnNext = order => {
  const { allSanityCases } = useStaticQuery(graphql`
    query {
      allSanityCases { nodes { 
        order, 
        title,
        slug { current },
        description { _rawEn, _rawPl }
      }}
    }
  `);

  const max = allSanityCases.nodes.length - 1;
  const next = (order + 1 > max) 
    ? allSanityCases.nodes.find(item => item.order == 0) 
    : allSanityCases.nodes.find(item => item.order == order + 1);

  return next;
};
