import React from "react"
import { socialMedia } from "@utils/variables";
import { useTranslation } from "react-i18next"
import tw from "twin.macro";

const SocialMediaWrapper = tw.div`flex mb-10 text-sm text-second leading-32 -tracking-021 lg:hidden`;

const SocialMediaTitle = tw.div`font-pangramBold mr-20`;

const SocialMediaLink = tw.a`mr-25`;

const MailWrapper = tw.div`text-sm text-second leading-32 lg:hidden`;

const MailTitle = tw.span`font-pangramBold mr-20 -tracking-021`;

const NavContact = () => {
  const { t } = useTranslation();

  return (
    <>
      <SocialMediaWrapper>
        <SocialMediaTitle>
          {t('Social media:Social media short')}
        </SocialMediaTitle>
        {socialMedia.map((name: string, index: number) => (
          <SocialMediaLink
            href={t(`Social media:${name} address`)}
            key={`socialMedia${index}`}
            target="_blank"
            rel="noopener"
          >
            {t(`Social media:${name}`)}
          </SocialMediaLink>
        ))}
      </SocialMediaWrapper>
      <MailWrapper>
        <MailTitle>
          {t('Contact:Email')}
        </MailTitle>
        <a href={`mailto:${t('Footer:Office email')}`}>
          {t('Contact:Office email')}
        </a>
      </MailWrapper>
    </>
  )
};

export default NavContact;
