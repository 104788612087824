import { useState, useLayoutEffect } from 'react';

const useWindowSize = () => {
  const isSSR = typeof window !== "undefined";
  const [windowSize, setWindowSize] = useState({ width: isSSR ? window.innerWidth : 0 });

  const changeWindowSize = () => {
    setWindowSize({ width: window.innerWidth });
  }

  useLayoutEffect(() => {
    window.addEventListener("resize", changeWindowSize);

    return () => {
      window.removeEventListener("resize", changeWindowSize);
    };
  }, []);

  return windowSize;
};

export default useWindowSize;
